<template>
  <div style="height:100%" class="w-100 px-4 black d-flex align-center justify-center">
      <div style="max-width:400px" class="mb-12 pb-12 text-center">

          <h1  style=" text-shadow: 4px 4px #ec38bc, 8px 8px #7303c0;
                      font-family: 'Permanent Marker', cursive !important;
                    " class="my-4 white--text">Unboxed Party Pass({{ qty }})</h1>
          <div class="d-flex align-center justify-space-between">

            <v-switch class="my-1" :value="cameras" dark @change="cameras = !cameras"
            :label="!cameras? 'Off camera':'On camera'"
></v-switch>
<p class="mb-2 grey--text"><v-btn :disabled="!cameras" class="mr-2 mb-2" color="grey lighten-2" fab x-small  @click="onCameraChange()"><v-icon color="black">mdi-refresh</v-icon> </v-btn>{{camera == 'auto'? 'Front': 'Back' }} camera</p>
</div>
            <v-card  width="100%" style="  box-shadow: 4px 4px #ec38bc, 8px 8px #7303c0;position:relative" class="shadow ma-auto" min-width="400px" height="250px">
            <qrcode-stream  :camera="camera" v-if="!dataUrl && tickets.length && cameras" @init="onInit" @decode="onDecode"></qrcode-stream>
            <v-card width="100%" height="100%" class="justify-center d-flex align-center"  v-else> 
            <div class="text-center">
                <h1 :style="status?'color:green':status == null? '':'color:red'">{{status? 'VALID': status == null? '': 'INVALID'}}</h1>
                <h1 :style="status?'color:green':status == null? '':'color:red'">{{status? 'ADMIT '+ admit: status == null? '': ''}}</h1>
                <h1 v-if="checked ">SHOW YOUR TAG</h1>
                <h1 v-if="!tickets.length ">LOADING USERS...</h1>
              </div>
            </v-card>
            </v-card>
          <h1 class=" grey--text">{{ dataUrl }}</h1>
          <h1 class="my-4 grey--text ">OR</h1>
          <v-layout>
            <v-flex xs8>
              <v-text-field class="mr-2" tile dark v-model="dataUrl" placeholder="Enter Ticket Code" solo size="large"></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-btn style="box-shadow: 4px 4px #ec38bc, 8px 8px #7303c0;" tile dark block height="48px" @click="onDecode(dataUrl)"><v-icon>mdi-arrow-right</v-icon></v-btn>
            </v-flex>
          </v-layout>
          <h1 class="my-4 grey--text ">{{ usrs.length }} in the Box</h1>
       
      </div>

  </div>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

* {
  font-family: "Permanent Marker", cursive !important;
}

</style>
<script>
import {QrcodeStream} from 'vue-qrcode-reader'

export default {
  components:{QrcodeStream},
data() {
  return {
    dataUrl: null,
    qty: 0,
    admit: 0,
    status: null,
    checked: false,
    cameras: true,
    tickets: [],
    camera:'auto',
    usrs: []
  }
},
  mounted(){
    const usrs = localStorage.getItem('usrs4');
    if (usrs) {
      this.usrs = JSON.parse(usrs)
    }else{
      localStorage.setItem('usrs4', '')
    }
//     if (localStorage.getItem("usrs") !== null)localStorage.removeItem('usrs')
    
//     if (localStorage.getItem("usrs2") !== null) {
//   //...
//   const usrs = localStorage.getItem('usrs2');
//   this.usrs = JSON.parse(usrs)
// }else{
//   localStorage.setItem('usrs2', JSON.stringify(this.usrs))

// }
fetch("https://backend.unboxedparty.com/api/tickets?event_id=3", {
  method: "GET",
  headers: {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Accept': 'application/json'
    }})
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error("Something went wrong");
  })
  .then((res) => {
    console.log(res)
  this.tickets = res.success.tickets
  this.qty = res.success.tickets_no
  })
  .catch(() => {
    alert("An error has occurred");
    this.loading = false;
  });
},
methods: {
  onCameraChange () {
    this.cameras = false
    this.camera = this.camera =='front' ? 'auto': 'front'
    setTimeout(() => {
        this.cameras = true
        }, 2000);
  },
  async onDecode(dataUrl) {
    if(this.dataUrl !== null) return
    this.checked = false
    this.dataUrl = dataUrl.toLowerCase()
    this.checked = await this.usrs.some((el)=>{
        return el.code.toLowerCase() == dataUrl.toLowerCase();
    })
    this.status = await this.tickets.some((el)=>{
      return el.code.toLowerCase() == dataUrl.toLowerCase();
    })
    if (this.status) {
      // var usr = {}
      //  usr = await this.usrs.find((el)=>{
      //   return el.code.toLowerCase() == dataUrl.toLowerCase();
      // }) || {}
      var user = await this.tickets.find((el)=>{
      return el.code.toLowerCase() == dataUrl.toLowerCase();
    })
    this.admit = user.qty
      // if(!this.checked ){
      //   usr.checked 
      // }
        var usrs = this.usrs
        this.admit = user.qty
        if (!this.checked) {
          user.checked = true
         await usrs.push(user)
         this.usrs = usrs
        await localStorage.setItem('usrs4', JSON.stringify(usrs));
        }else{
          this.checked = true
        }
      }
      setTimeout(() => {
        this.checked = false
         this.status = this.dataUrl = null
        }, 2000);
  },
 
  async onInit(promise) {
    try {
      await promise;
    } catch (error) {
      if (error.name === "NotAllowedError") {
        this.error = "ERROR: you need to grant camera access permisson";
        alert(this.error);
      } else if (error.name === "NotFoundError") {
        this.error = "ERROR: no camera on this device";
        alert(this.error);
      } else if (error.name === "NotSupportedError") {
        this.error = "ERROR: secure context required (HTTPS, localhost)";
        alert(this.error);
      } else if (error.name === "NotReadableError") {
        this.error = "ERROR: is the camera already in use?";
        alert(this.error);
      } else if (error.name === "OverconstrainedError") {
        this.error = "ERROR: installed cameras are not suitable";
      } else if (error.name === "StreamApiNotSupportedError") {
        this.error = "ERROR: Stream API is not supported in this browser";
        alert(this.error);
      }
    }
  },
},

}
</script>